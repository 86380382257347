import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import loadable from '@loadable/component'
import { SanityHomePage } from '@utils/types'
const ProjectsCarousel = loadable(() => import('@components/projectsCarousel'))
const CarouselComponent = loadable(() => import('@components/carousel'))
const SquaredSection = loadable(() => import('@components/squaredSection'))
const SimpleTextSection = loadable(() =>
  import('@components/simpleTextSection'),
)

type Props = {
  data: {
    sanityHome: SanityHomePage
  }
  location: Location
}

const IndexPage = ({ data: { sanityHome }, location }: Props) => {
  const metadata = {
    location: location.href,
    ...sanityHome?.seo,
  }

  return (
    <>
      <SEO {...metadata} />
      <div className="home-styles">
        {sanityHome?.carousel && (
          <CarouselComponent images={sanityHome.carousel} />
        )}
        {sanityHome?.simpleTextSection && (
          <SimpleTextSection
            simpleTextSection={sanityHome?.simpleTextSection}
          />
        )}
        {sanityHome?.carouselWithCategories && (
          <ProjectsCarousel
            projects={sanityHome?.carouselWithCategories}
            location={location}
          />
        )}
        {sanityHome?.squaredSection && (
          <SquaredSection squaredSection={sanityHome?.squaredSection} />
        )}
      </div>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    sanityHome(_id: { in: ["drafts.homePage", "homePage"] }) {
      seo {
        description
        keywords
        image
        title
        twitterCard
        twitterCreator
        twitterSite
      }
      slug {
        current
      }
      title
      carousel {
        alt
        slug {
          current
        }
        asset {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      simpleTextSection {
        _rawTitle(resolveReferences: { maxDepth: 10 })
        _rawBody(resolveReferences: { maxDepth: 10 })
      }
      carouselWithCategories {
        category
        projectImage {
          image {
            alt
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
          _rawInfo(resolveReferences: { maxDepth: 10 })
          button {
            type
            slug {
              current
            }
            title
          }
        }
      }
      squaredSection {
        _rawText(resolveReferences: { maxDepth: 10 })
        flip
        backgroundColor {
          title
          value
        }
        image {
          alt
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }
`
